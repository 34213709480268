import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./components/common/Header";
import Swap from "./components/sections/Swap";
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi';
import {base, linea, zkSync} from 'wagmi/chains'
 
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
 
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// "start": "react-app-rewired start",

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});
const { chains, publicClient, webSocketPublicClient } = configureChains(
    [base, linea, zkSync],
    // [mainnet]
    [publicProvider()],
)
console.log("chains in app", chains)
const config = createConfig({
    chains: chains,
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
            chains,
            options: {
                appName: 'wagmi',
            },
        }),
        new WalletConnectConnector({
            chains,
            options: {
                projectId: 'e163def497d4ac886a3b3423c66e9a66',
            },
        }),
        new InjectedConnector({
            chains,
            options: {
                name: 'Injected',
                shimDisconnect: true,
            },
        }),
    ],
    publicClient,
    webSocketPublicClient,
})
function App() {
    return (
        <WagmiConfig config={config}>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <Router>
                    <ToastContainer pauseOnFocusLoss={false} />
                        <Routes>
                        <Route path="/" element={<Swap />} />
                        </Routes>
                    </Router>
                
            </ThemeProvider>
        </WagmiConfig>
            
    );
}

export default App;
