import React, { useEffect, useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';
import { networks } from "../../hooks/network";

export default function Header({ setShowConnectWallet, setShowSelectNetwork }) {
    const { isConnected } = useAccount();
    const { disconnect } = useDisconnect();
    const { chain } = useNetwork();
    const [netErr, setNetErr] = useState(false);

    useEffect(() => {
        if (chain) {
            setNetErr(!networks[chain?.id]);
        }
    }, [chain]);

    return (
        <AppBar position="static" sx={{ boxShadow: "none", background: "transparent" }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1 }} />

                    {/* Logo Placeholder */}
                    {!netErr && (
                        <IconButton
                            sx={{ marginRight: 2 }}
                            onClick={() => setShowSelectNetwork(true)}
                            size="large"
                        >
                            <img
                                src="/assets/network/heartbeat.png"
                                alt="Network Selector"
                                width={30}
                                height={30}
                            />
                        </IconButton>
                    )}

                    {/* Network Selector - shown if there is a selected chain and no network error */}
                    {!netErr && chain && (
                        <Box
                            sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}
                            onClick={() => setShowSelectNetwork(true)}
                        >
                            <img
                                src={networks[chain.id]?.imgSrc || "/assets/network/heartbeat.png"}
                                alt={chain.name}
                                width={30}
                                height={30}
                            />
                            <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
                                {chain.name}
                            </Typography>
                        </Box>
                    )}

                    {/* Connect/Disconnect Button */}
                    <Button
    onClick={!isConnected ? () => setShowConnectWallet(true) : disconnect}
    variant={isConnected ? "outlined" : "contained"}
    sx={{
        height: '40px',
        width: '120px',
        ...(isConnected ? {
            color: '#535353', // Text color when connected
            borderColor: '#535353', // Border color when connected (for outlined variant)
            '&:hover': {
                backgroundColor: 'rgba(0, 128, 0, 0.08)', // Light green background on hover when connected
                borderColor: 'green', // Keep border color on hover
            },
        } : {
            backgroundColor: '#535353', // Background color when not connected
            color: '#fff', // Text color when not connected
            '&:hover': {
                backgroundColor: 'silver', // Darker background on hover when not connected
            },
        })
    }}
>
    {isConnected ? 'Connected' : 'Connect'}
</Button>
                </Toolbar>
            </Container>
        </AppBar>

      
    );
}